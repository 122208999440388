import React from 'react';
import './App.css';
import PaidLeave from "./components/paidleave/PaidLeave";

function App() {
  return (
    <div className="App">
      <PaidLeave/>
    </div>
  );
}

export default App;
